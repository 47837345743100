import React, { useCallback, useContext, useEffect, useState } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { PageContext } from '../../../../lib/contexts/pageContext';
import {
  Button,
  Chip,
  Link,
  List,
  ListItem,
  Paper,
  Tab,
  TableCell,
  TableContainer,
  Tabs,
  Typography,
  useTheme
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import moment from '../../../../lib/moment';
import { useAbility } from '@casl/react';
import { NILL, participantNeeds, programAreas } from '../../../../lib/constants';
import { getStaffNames, graphQuery } from '../../../../lib/api';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { AbilityContext, Subjects, toGraphQuery } from '../../../../lib/permissions';
import MuiTable from '../../../common/MuiTable';
import { subject } from '@casl/ability';
import { deleteEvent, deleteSession } from '../../../../graphql/mutations';
import UserContext from '../../../../lib/contexts/userContext';
import { listEventsWithLessons } from '../eventFunctions';
import { createFilter, defaultComparator } from '../../../../lib/utils';
import TextBreaks from '../../../common/TextBreaks';
import { useConfirm } from '../../../common/hooks/useConfirm';

const EventDetails = ({ setTitle }) => {
  const [event, setEvent] = useState(null);
  const [deleting, setDeleting] = useState(false);

  const { userState: { userRecord }, authenticated } = useContext(UserContext);

  const {
    updateCrumbLabel,
    pageLoading,
    setPageLoading,
    authorized,
    setPageErrorMessage,
  } = useContext(PageContext);

  const { eventID } = useParams();

  const navigate = useNavigate();
  const confirm = useConfirm();

  const ability = useAbility(AbilityContext);

  const fetchEvent = useCallback(async () => {
    if (!eventID || ability.cannot('view', Subjects.EVENT_DETAILS)) {
      return;
    }
    try {
      // Filter the event based on user permissions (if they can't view it, it won't return)
      const filterRules = toGraphQuery(ability, 'view', Subjects.EVENT_DETAILS);
      const filter = createFilter(eventID, filterRules);
      const result = await API.graphql({
        ...graphqlOperation(listEventsWithLessons, { filter, limit: 9999 }),
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
      const [eventData] = result.data.listEvents.items;
      // Replace staff emails with names from myINEP or Cognito
      if (eventData) {
        const names = await getStaffNames([eventData.created_by, eventData.updated_by]);
        eventData.created_by_names = names[eventData.created_by];
        eventData.updated_by_names = names[eventData.updated_by];
      }
      return eventData;
    } catch (error) {
      console.log('Error: ', error);
    }
  }, [ability, eventID]);

  useEffect(() => {
    // Wait for permissions to get loaded for the users role before doing permission checks
    if (!eventID || !authenticated) {
      return;
    }

    let active = true;

    fetchEvent().then((data) => {
      if (!active) {
        return;
      }
      if (data?.id && data?.event_name) {
        updateCrumbLabel(data.id, data.event_name);
        setEvent(data);
      } else {
        setEvent(false);
        setTitle('Error Accessing Page');
      }
    }).catch(() => {
      if (!active) {
        return;
      }
      setEvent(false);
      setTitle('Error Accessing Page');
    }).finally(() => {
      if (!active) {
        return;
      }
      setPageLoading(false)
    });

    return () => {
      active = false;
    }
  }, [fetchEvent, authenticated, setPageLoading, setTitle, updateCrumbLabel, userRecord, eventID]);

  const handleDelete = async () => {

    if (ability.cannot('delete', subject(Subjects.EVENT, event ))) {
      console.log(event);
      setPageErrorMessage('You do not have permission to delete this event.');
      return false;
    }

    const confirmDelete = await confirm({
      title: 'Are you sure you want to delete this event?',
      description: 'If you delete this event, all its sessions will also be deleted. This cannot be undone.',
      confirmationButtonProps: {
        variant: 'contained',
      },
      confirmationText: 'DELETE EVENT',
      cancellationText: 'KEEP EVENT',
    })
      .then(() => true)
      .catch(() => false);

    if (!confirmDelete) {
      return;
    }
    // TODO: Check to see if any sessions have participants first, if they do, abort.

    setDeleting(true);
    let deleted = false;
    try {
      await Promise.all(event.sessions.items.map(({ id }) =>
        graphQuery(deleteSession, { input: { id } })));
      const result = await graphQuery(deleteEvent, { input: { id: eventID } });

      if (result.data.deleteEvent?.id) {
        deleted = true;
      }
    } catch (err) {
      console.log('Error: ', err);
    }
    if (deleted) {
      navigate('/events', { state: { message: 'The event and its sessions have been deleted.' } });
    } else {
      setPageErrorMessage('An error occurred while deleting event. Please contact <support email address> for assistance.');
      setDeleting(false);
    }
  };

  const getProgramAreaLabel = (area) => {
    const opt = programAreas.find((pa) => pa.id === area);
    return opt?.label;
  };

  const programAreaLabels = (areas) => areas.reduce((acc, area) => {
    const label = getProgramAreaLabel(area);
    if (label) {
      acc.push(label);
    }
    return acc;
  }, []).join(', ');

  const theme = useTheme();

  const headCells = [
    {
      id: 'date',
      label: 'Date & Time',
    },
    {
      id: 'status',
      label: 'Status',
    },
    {
      id: 'lesson_name',
      label: 'Lesson',
    },
    {
      id: 'delivery_method',
      label: 'Delivery Method',
    },
    {
      id: 'delivery_site',
      label: 'Delivery Site & Address',
    },
  ];

  /**
   * Returns a comparison function.
   *
   * @param order
   * @param orderBy
   */
  const getComparator = (order, orderBy) => {
    const ord = order === 'desc' ? -1 : 1;
    const getLessonName = (e) => e?.lesson?.lesson_name || e?.lesson?.booth_topic || NILL;
    switch (orderBy) {
      case 'date':
        return (a, b) => {
          const aStart = a?.start ? moment(a.start) : null;
          const bStart = b?.start ? moment(b.start) : null;
          if (a === b) return 0;
          if (!aStart) return -1 * ord;
          if (!bStart) return 1 * ord;
          return aStart.diff(bStart) * ord;
        };
      case 'lesson_name':
        return (a, b) => getLessonName(a).localeCompare(getLessonName(b)) * ord;
      case 'delivery_method':
      case 'delivery_site':
        const getText = (e) => [e.delivery_location, e.delivery_url, e.other_details].join();
        return (a, b) => getText(a).localeCompare(getText(b)) * ord;
      case 'status':
        const nonNull = (e) => e[orderBy] || NILL;
        return (a, b) => {
          return nonNull(a).localeCompare(nonNull(b)) * ord;
        };
      default:
        return defaultComparator;
    }
  };

  const getSessionDates = (start, end) => {
    const mStart = moment(start);
    const mEnd = moment(end);
    const diff = mEnd.diff(mStart, 'minutes');
    if (diff > ((24*60) - 1)) {
      return `${mStart.format('MM/DD/YYYY h:mm A')} - ${mEnd.format('MM/DD/YYYY h:mm A')}`;
    }
    return `${mStart.format('MM/DD/YYYY h:mm A')} - ${mEnd.format('h:mm A')}`;
  };

  const getParticipantNeeds = () => event.participant_needs.map((id) => {
    if (id === 'other') {
      return `Other: ${event.participant_needs_other}`;
    }
    const need = participantNeeds.find((pn) => pn.id === id);
    return need?.label || id;
  });

  const getStatusIcon = (status) => {
    switch (status) {
      case 'CANCELED':
        return <CloseIcon color="error" sx={{ marginLeft: '8px' }} />;
      default:
        return <span style={{ marginLeft: '14px' }}>{NILL}</span>;
    }
  };

  const getDeliveryCells = (row) => {
    // Sort delivery methods so In Person comes first and attach associated field data
    const methods = row.delivery_method.sort();
    const details = methods.map((method) => {
      switch (method) {
        case 'In Person':
          return [row.delivery_location];
        case 'Virtual/Live Online':
          return [row.delivery_url, row.other_details];
        default:
          return [];
      }
    });
    return (
      <>
        <TableCell align="left">
          { methods.length > 1 ? (
            <List sx = {{
              listStyleType: 'disc',
              pl: '18px',
              '& .MuiListItem-root': {
                display: 'list-item',
                paddingTop: 0,
                paddingBottom: 0,
              },
            }}>
              { methods.map((method, i) => (
                <ListItem key={`method-${i}`} disableGutters>{ method }</ListItem>
              ))}
            </List>
          ) : <TextBreaks texts={methods} /> }
        </TableCell>
        <TableCell align="left">
          { details.length > 1 ? (
            <List
              sx = {{
                listStyleType: 'disc',
                pl: 2,
                '& .MuiListItem-root': {
                  display: 'list-item',
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              }}
            >
              { details.map((detail, i) => (
                <ListItem key={`method-details-${i}`} disableGutters>
                  <TextBreaks texts={ detail } />
                </ListItem>
              ))}
            </List>
          ) : <TextBreaks texts={details} /> }
        </TableCell>
      </>
    );
  };

  return (
    <div id="pagewrapper" className={'with-two-sidebar'} style={{ padding: 0 }}>
      <div id="content-wrapper" className={'event-details-card'}>
        {authorized && event && (
          <>
            <Paper elevation={3}>
              <Box padding={3}>
                <Typography variant="h4" color="ilstorm.main">
                  {event.event_name}
                </Typography>
                { event.internal_only && (
                  <Chip label="Training/Test Event" />
                )}
                <List dense={true}>
                  <ListItem disablePadding>
                    Program Area(s): {programAreaLabels(event.program_areas)}
                  </ListItem>
                  { event.curriculum && (
                    <ListItem disablePadding>
                      Primary Curriculum: {event?.curriculum?.curriculum_name}
                    </ListItem>
                  )}
                  { event.curriculum?.additional?.items?.length > 0 && (
                    <ListItem disablePadding>
                      Additional Curriculum: {event?.additional?.additional_name || 'None'}
                    </ListItem>
                  )}
                  <ListItem disablePadding>
                    Partner Site: {event.event_partner}
                  </ListItem>
                  { event.participant_needs?.length > 0 && (
                    <ListItem disablePadding>
                      Specific Needs: {getParticipantNeeds().join(', ')}
                    </ListItem>
                  )}
                  { event?.special_project && (
                    <ListItem disablePadding>
                      Special Project: {event.special_project}
                    </ListItem>
                  )}
                  <ListItem disablePadding>
                    Created By: {event.created_by_names}
                  </ListItem>
                  <ListItem disablePadding>
                    Last Modified: {`${event.updated_by_names}, ${moment(event.updatedAt).format('MM/D/y h:mm A')}`}
                  </ListItem>
                </List>
                <Stack direction="row" justifyContent="space-between" sx={{ marginTop: '20px' }}>
                  <Button
                    variant="filled"
                    startIcon={<EditIcon />}
                    component={RouterLink}
                    to={`/events/edit/${eventID}`}
                  >
                    Edit Event
                  </Button>
                  <Button
                    variant="filled"
                    color="error"
                    startIcon={<ClearIcon />}
                    disabled={deleting}
                    onClick={handleDelete}
                  >
                    Delete Event
                  </Button>
                </Stack>
              </Box>
            </Paper>
            <Tabs
              value={0}
              textColor="primary"
              indicatorColor="primary"
              sx={{
                marginTop: '32px',
                '.MuiButtonBase-root': {
                  fontSize: '18px'
                }
              }}
            >
              <Tab label="SESSIONS" value={0} />
            </Tabs>
            <Box mt="28px">
              <Button
                variant="filled"
                startIcon={<AddIcon />}
                component={RouterLink}
                to={`/events/${eventID}/sessions/add`}
              >
                Add Session
              </Button>
              { event.sessions.items.length > 0 && (
                <TableContainer sx={{ marginTop: '32px' }}>
                  <MuiTable
                    defaultOrderBy="date"
                    headCells={headCells}
                    data={event.sessions.items}
                    getComparator={getComparator}
                    alignTop
                  >
                    {(row) => (
                      <>
                        <TableCell
                          component="th"
                          scope="row"
                        >
                          <Link to={`/events/${row.eventID}/${row.id}/`} component={RouterLink}>
                            {getSessionDates(row.start, row.end)}
                          </Link>
                        </TableCell>
                        <TableCell align="left">{getStatusIcon(row.status)}</TableCell>
                        <TableCell align="left">{row.lesson?.lesson_name || row.lesson?.booth_topic || NILL}</TableCell>
                        { getDeliveryCells(row) }
                      </>
                    )}
                  </MuiTable>
                </TableContainer>
              )}
              { event.sessions.items.length < 1 && (
                <Typography
                  variant="h6"
                  fontSize={theme.typography.pxToRem(16)}
                  fontWeight="500"
                  sx={{ marginTop: '32px', width: '100%', textAlign: 'center' }}
                >
                  There are no sessions for this event.
                </Typography>
              )}
            </Box>
          </>
        )}
        { (!pageLoading && event === false) && (
          <>
            You do not have access, or the event was not found.
          </>
        )}
      </div>
    </div>
  );
};

export default EventDetails;
